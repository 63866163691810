exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mass-tsx": () => import("./../../../src/pages/mass.tsx" /* webpackChunkName: "component---src-pages-mass-tsx" */),
  "component---src-pages-temperature-tsx": () => import("./../../../src/pages/temperature.tsx" /* webpackChunkName: "component---src-pages-temperature-tsx" */),
  "component---src-pages-volume-tsx": () => import("./../../../src/pages/volume.tsx" /* webpackChunkName: "component---src-pages-volume-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-history-of-the-kilogram-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/blog/history-of-the-kilogram.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-history-of-the-kilogram-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-history-of-the-meter-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/blog/history-of-the-meter.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-history-of-the-meter-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-history-of-the-stone-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/blog/history-of-the-stone.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-history-of-the-stone-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-different-measurement-systems-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/blog/why-different-measurement-systems.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-different-measurement-systems-mdx" */)
}

